import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'

import Button from 'react-bootstrap/Button'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

class ImageGallery extends React.Component {
  componentDidMount() {
    // sketchy fix for proper gallery display
    // FF,chrome don't center unless a resize event is fired?
    // resize event causes IE to crash
    if (!/MSIE|Trident/.test(window.navigator.userAgent)) {
      window.dispatchEvent(new Event('resize'))
    }
  }
  render() {
    const { images, maxWidth = '200px', maxHeight = '200px' } = this.props
    const propsIfMoreThanOne =
      images.length > 1
        ? {
            arrowLeft: (
              <Button variant="outline-primary mr-1">
                <i className="fa fa-arrow-left" />
              </Button>
            ),
            arrowRight: (
              <Button variant="outline-primary ml-1">
                <i className="fa fa-arrow-right" />
              </Button>
            ),
            addArrowClickHandler: true,
          }
        : {}
    return (
      <Carousel {...propsIfMoreThanOne}>
        {images.map((image, i) => (
          <Img
            key={i}
            fixed={image.fixed}
            alt={image.title}
            style={{ maxWidth, maxHeight }}
          />
        ))}
      </Carousel>
    )
  }
}

export default ImageGallery
